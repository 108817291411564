/* eslint-disable react-hooks/exhaustive-deps */
import processLocale, { FOURSIXTY_LOCALES_MAP } from '@lib/locale'
import { useRouter } from 'next/router'
import { RichText, RichTextBlock } from 'prismic-reactjs'
import { FC, useEffect, useRef, useState } from 'react'

interface PairingsProps {
  title?: string | undefined
  description?: string | RichTextBlock[] | undefined
  dataForUrl: boolean
  productsIds?: []
  paddingTop?: boolean
}

const Pairings: FC<PairingsProps> = ({ title, description, dataForUrl, productsIds, paddingTop = true }) => {
  const { locale } = useRouter()
  const containerRef = useRef<any>(null)
  const currentLocale = processLocale(locale)
  const [pairingLoaded, setPairingLoaded] = useState(false)
  useEffect(() => {
    return () => {
      document.querySelector('.fs-slider_v2_5')?.remove()
    }
  }, [])
  useEffect(() => {
    if (!containerRef || !containerRef.current) return
    const container = containerRef.current
    if (container && !pairingLoaded) {
      const tag = document.createElement('script')
      tag.src = '//foursixty.com/media/scripts/fs.slider.v2.5.js'
      tag.setAttribute('data-has-posts-selector', '#foursixty-hideable-container')
      tag.setAttribute('data-feed-id', FOURSIXTY_LOCALES_MAP[currentLocale.toLowerCase()]?.dataFeedId)
      tag.setAttribute('data-theme', 'slider_v2_5')
      tag.setAttribute('data-cell-size', '33%')
      tag.setAttribute('data-for-url', String(dataForUrl))
      tag.setAttribute('data-shopify-product-overrides-link-text', 'true')
      tag.setAttribute('data-connector-filter', '30111')
      tag.setAttribute('data-shopify-add-to-cart', 'false')
      tag.setAttribute('data-product-ids', String(productsIds || []))
      const el = document.createElement('div')
      el.className = 'pb-24 heading-5'
      el.textContent = 'As styled on @sheetsociety'
      container.appendChild(el)
      container.appendChild(tag)
      setPairingLoaded(true)
    }
  }, [containerRef, currentLocale, dataForUrl, productsIds])
  return (
    <div>
      {title && (
        <div className="max-w-600 mb-24 tablet:mb-32">
          <h2 className="heading-5 mb-8">{title}</h2>
          {description && (
            <div className="body">{typeof description === 'string' ? description : RichText.render(description)}</div>
          )}
        </div>
      )}
      <div id="foursixty-hideable-container" ref={containerRef} style={{ display: 'none' }}></div>
      <style jsx global>
        {`
          #foursixty-hideable-container
            > .fs-slider_v2_5.fs-desktop.fs-slider-container.fs-narrow-timeline
            ~ .fs-slider_v2_5.fs-desktop.fs-slider-container.fs-narrow-timeline {
            display: none !important;
          }
          .fs-slider-next-button,
          .fs-slider-prev-button {
            background-color: #010101;
            width: 48px !important;
            height: 48px !important;
            top: 50%;
            transform: translate(0, -50%);
          }
          .fs-slider-prev-button {
            left: 18px;
          }
          .fs-slider-prev-button {
            right: 18px;
          }
          .fs-slider-prev-button:before {
            color: black !important;
            width: 15px !important;
            height: 15px !important;
            border-top: 2px solid #dddddd !important;
            border-left: 2px solid #dddddd !important;
            border-right: 0 !important;
            border-bottom: 0 !important;
            content: '' !important;
            display: inline-block !important;
            margin: 0 5px;
            transform: rotate(-45deg) !important;
          }
          .fs-slider-next-button:before {
            color: black !important;
            width: 15px !important;
            height: 15px !important;
            border-top: 2px solid #dddddd !important;
            border-left: 2px solid #dddddd !important;
            border-right: 0 !important;
            border-bottom: 0 !important;
            content: '' !important;
            display: inline-block !important;
            margin: 0 5px;
            transform: rotate(135deg) !important;
          }
          .fs-slider-next-button,
          .fs-slider-prev-button {
            background-color: #010101;
            width: 48px !important;
            height: 48px !important;
            top: 50%;
            transform: translate(0, -50%);
            right: 8px;
          }
          .fs-slider-next-button svg,
          .fs-slider-prev-button svg {
            display: none;
          }

          .fs-slider-next-button:hover,
          .fs-slider-prev-button:hover {
            opacity: 0.5;
          }
          .fs-has-links::after {
            border-radius: 999px;
            padding: 12px 18px;
            border: 1px solid rgb(255, 255, 255);
            color: rgb(255, 255, 255);
            content: 'SEE THE LOOK';
          }
          .fs-has-links:hover:after {
            opacity: 0.8;
          }
          .fs-wrapper {
            height: auto;
          }
          .fs-entry-container {
            height: 0 !important;
            // width: 33% !important;
            padding-top: 25% !important;
          }

          .fs-wrapper div.fs-text-container * {
            color: rgb(188, 171, 160);
          }
          .fs-wrapper div.fs-text-container {
            background-color: rgba(31, 31, 31, 0.6);
            margin: 0px;
          }
          div.fs-entry-date {
            display: none;
          }
          div.fs-service-icon {
            display: none;
          }
          div.fs-entry-title {
            display: none;
          }
          .fs-wrapper div.fs-timeline-entry {
            margin: 2px;
          }
          .fs-link-text {
            font-family: GrotesqueRegular;
            font-size: 14px;
            line-height: 24px;
          }
          .fs-link-text::after {
            content: 'SHOP NOW';
            display: block;
            max-width: 130px;
            color: #fff;
            background-color: black;
            border-radius: 999px;
            padding: 10px 14px;
            margin: 16px auto;
            font-family: GrotesqueRegular;
          }
          .fs-link-text:hover::after {
            opacity: 0.8;
          }
          .fs-underline {
            display: none;
          }
          div.fs-detail-title {
            font-family: GrotesqueRegular;
            font-size: 12px;
            line-height: 20px;
          }
          .fs-post-info a:link {
            font-family: GrotesqueRegular;
            color: #595959;
          }
          .fs-wrapper div.fs-timeline-entry {
            margin-right: 16px;
          }
          @media (max-width: 375px) {
            .fs-entry-container {
              min-width: 90% !important;
              min-height: 292px !important;
              max-width: 292px;
            }
            .fs-slider_v2_5-feed.fs-wrapper {
              height: 292px;
            }
            .fs-wrapper div.fs-timeline-entry {
              margin-right: 12px;
              height: 292px;
              width: 292px;
            }
          }
          @media (min-width: 376px) and (max-width: 425px) {
            .fs-entry-container {
              min-width: 90% !important;
              min-height: 340px !important;
            }
            .fs-wrapper div.fs-timeline-entry {
              margin-right: 12px;
            }
          }
        `}
      </style>
    </div>
  )
}

export default Pairings
