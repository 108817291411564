import { FC } from 'react'
import { FourSixtyPairings } from '@components/common'
import cn from 'classnames'

interface InstagramBlockProps {
  primary: {
    block_title: string
    description: string
    background_colour?: boolean
    padding_top?: boolean
  }
  applyWrapper?: boolean
}

const InstagramBlock: FC<InstagramBlockProps> = ({ primary, applyWrapper = true }) => {
  const { block_title, description, padding_top } = primary || {}
  return (
    <div className={cn('pb-32 wrapper tablet:pb-80 bg-ecru', { wrapper: applyWrapper })}>
      <FourSixtyPairings title={block_title} description={description} dataForUrl={false} paddingTop={padding_top} />
    </div>
  )
}

export default InstagramBlock
